import React, {Fragment} from 'react'
import PropagandaTennis from "../components/PropagandaTennis";

import {Helmet} from 'react-helmet'
import Div100vh from 'react-div-100vh'
import styled from "styled-components"

const Div100vhNoScroll = styled(Div100vh)`
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-overflow-scrolling: touch;
`

export default function Tennis() {
  return (<Fragment>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
    </Helmet>
    <Div100vhNoScroll><PropagandaTennis phone={false}/></Div100vhNoScroll>
  </Fragment>)
}
